const sidebar = document.getElementById("sidebar");

if (sidebar) {
  const toggleSidebarMobile = (
    sidebar,
    sidebarBackdrop,
    toggleSidebarMobileHamburger,
    toggleSidebarMobileClose
  ) => {
    sidebar.classList.toggle("hidden");
    sidebarBackdrop.classList.toggle("hidden");
    if (toggleSidebarMobileHamburger)
      toggleSidebarMobileHamburger.classList.toggle("hidden");
    if (toggleSidebarMobileClose)
      toggleSidebarMobileClose.classList.toggle("hidden");
  };

  const toggleSidebarMobileEl = document.getElementById("toggleSidebarMobile");
  const sidebarBackdrop = document.getElementById("sidebarBackdrop");
  const toggleSidebarMobileHamburger = document.getElementById(
    "toggleSidebarMobileHamburger"
  );
  const toggleSidebarMobileClose = document.getElementById(
    "toggleSidebarMobileClose"
  );
  const toggleSidebarMobileSearch = document.getElementById(
    "toggleSidebarMobileSearch"
  );

  if (toggleSidebarMobileSearch) {
    toggleSidebarMobileSearch.addEventListener("click", () => {
      toggleSidebarMobile(
        sidebar,
        sidebarBackdrop,
        toggleSidebarMobileHamburger,
        toggleSidebarMobileClose
      );
    });
  }
  if (toggleSidebarMobileEl) {
    toggleSidebarMobileEl.addEventListener("click", () => {
      toggleSidebarMobile(
        sidebar,
        sidebarBackdrop,
        toggleSidebarMobileHamburger,
        toggleSidebarMobileClose
      );
    });
  }

  if (sidebarBackdrop) {
    sidebarBackdrop.addEventListener("click", () => {
      toggleSidebarMobile(
        sidebar,
        sidebarBackdrop,
        toggleSidebarMobileHamburger,
        toggleSidebarMobileClose
      );
    });
  }
}
