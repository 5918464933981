import { MapViewer } from "./mapViewer";
import { Tabs, Accordion } from "flowbite";
import * as webix from "webix/webix.js";
import { geoJson, tileLayer } from "leaflet";
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import { GEO_HOST } from "./constants";
import { aoiMarkerOptions, aoiPolyOptions, dataStyles } from "./mapStyles";
import { getLineChartOptions } from "./charts";
import ApexCharts from "apexcharts";
import { customPolygon } from "./custom";
import { initSummaryDrawer } from "./drawers";

let mapViewer,
  tabs,
  datalayers = {},
  dataCharts = {},
  dataTables = {},
  lineChart,
  layerControl,
  positionMarker,
  drawerSummary = {};

$(document).ready(function () {
  const homeMapDiv = document.getElementById("home:map_viewer");
  const tabsElement = document.getElementById("tabs-workbench");
  var accordionMap = document.getElementById("accordion-map");
  var accordionChart = document.getElementById("accordion-chart");
  var accordionTable = document.getElementById("accordion-table");

  if (homeMapDiv) {
    mapViewer = new MapViewer("home", { attributionControl: false });

    mapViewer.initMenuBarControl();
    mapViewer.initCustomLayerControl();

    layerControl = mapViewer.layerControl;

    mapViewer.setBaseMap("arcgisTerrain");
    mapViewer.attributionControl.addTo(mapViewer.navMap);
    mapViewer.attributionControl.setPosition("bottomleft");
    mapViewer.zoomControl.setPosition("topright");

    datalayers["aoiPoint"] = new L.FeatureGroup();
    datalayers["aoiPoint"].addTo(mapViewer.navMap);

    mapViewer.navMap.on(L.Draw.Event.CREATED, function (event) {
      var layer = event.layer;
      if (layer instanceof L.Polygon) {
        var latLongs = layer.getLatLngs()[0];

        var coords = "";
        for (var i = 0; i < latLongs.length; i++) {
          coords += latLongs[i].lng + " " + latLongs[i].lat + ",";
        }
        coords += latLongs[0].lng + " " + latLongs[0].lat;

        layer.setStyle(aoiPolyOptions);
        datalayers["aoiPoly"].addLayer(layer);
        layer
          .bindPopup(
            `<button  type="button" class="btn-identify-poly text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900">Click</button> to analyze this location`
          )
          .on("click", function () {
            console.log(this);
          });
      }
      if (layer instanceof L.Marker) {
        var latLng = layer.getLatLng();
        const marker = L.circleMarker(latLng, aoiMarkerOptions);
        datalayers["aoiPoint"].addLayer(marker);
        marker
          .bindPopup(
            `<button type="button" class="btn-identify-point text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900">Click</button> to analyze this location`
          )
          .on("click", function () {
            console.log(this);
          });
        marker.bindTooltip(
          "Point " + datalayers["aoiPoint"].getLayers().length,
          { permanent: true, offset: [5, 2], className: "my-label" }
        );
      }
    });

    mapViewer.navMap.on(L.Draw.Event.DRAWSTART, function (event) {});

    mapViewer.navMap
      .on("click", function (e) {
        let tab = tabs.getActiveTab();
        if (tab.id == "map") {
          console.log("Zoom to map ");
        } else if (tab.id == "chart") {
          if ($("input[name='radio-chart']:checked").val() == "ndvi") {
            showNDVIChart(e, tab);
          }
        } else {
          if ($("input[name='radio-table']:checked").val() == "ndvi") {
            showNDVITable(e, tab);
          }
        }

        // Clear the markers
        if (datalayers["aoiPoint"]) {
          datalayers["aoiPoint"].clearLayers();
        }

        // Clear selected districts
        if (datalayers["district"]?._selectedIds) {
          datalayers["district"].clearSelection();
        }

        // Clear selected provinces
        if (datalayers["province"]?._selectedIds) {
          datalayers["province"].clearSelection();
        }
      })
      .on("dblclick", function (e) {
        L.DomEvent.stopPropagation(e);
        console.log("Clicked");
        // map.zoomIn();
      })
      .on("zoomend", function (e) {
        L.DomEvent.stopPropagation(e);
      });

    $("#hideSidebarMobile").on("click", function (e) {
      L.DomEvent.stopPropagation(e);
      mapViewer.sideBarControlButton.update();
      $("#btn-hide-sidebar").css("display", "none");
      $("#btn-show-sidebar").css("display", "flex");
    });

    $("#btn-hide-sidebar").on("click", function (e) {
      L.DomEvent.stopPropagation(e);
      $(this).hide();
      mapViewer.sideBarControlButton.update();
      $("#btn-show-sidebar").css("display", "flex");
    });
    $("#btn-show-sidebar").on("click", function (e) {
      L.DomEvent.stopPropagation(e);
      $(this).hide();
      mapViewer.sideBarControlButton.update();
      if (!$("#hideSidebarMobile").is(":visible")) {
        $("#btn-hide-sidebar").css("display", "flex");
      }
    });
  }

  if (tabsElement) {
    const tabElements = [
      {
        id: "map",
        triggerEl: document.querySelector("#map-tab"),
        targetEl: document.querySelector("#map"),
      },
      {
        id: "chart",
        triggerEl: document.querySelector("#chart-tab"),
        targetEl: document.querySelector("#chart"),
      },
      {
        id: "table",
        triggerEl: document.querySelector("#table-tab"),
        targetEl: document.querySelector("#table"),
      },
    ];

    const options = {
      defaultTabId: "map",
      activeClasses:
        "text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500",
      inactiveClasses:
        "text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300",
      onShow: () => {},
    };

    const instanceOptions = {
      id: "tabs-workbench",
      override: true,
    };

    tabs = new Tabs(tabsElement, tabElements, options, instanceOptions);

    tabs.show("map");

    tabs.updateOnShow((event) => {
      // Clear the point layer
      datalayers["aoiPoint"].clearLayers();

      // Hide popup windows for charts and tables
      if (
        !document
          .getElementById("home:chart_viewer")
          .classList.contains("hidden")
      ) {
        document.getElementById("home:chart_viewer").classList.add("hidden");
      }

      if (
        !document
          .getElementById("home:table_viewer")
          .classList.contains("hidden")
      ) {
        document.getElementById("home:table_viewer").classList.add("hidden");
      }

      // hide drawers
      for (const property in drawerSummary) {
        drawerSummary[property].hide();
      }

      mapViewer.navMap.invalidateSize();
    });
  }

  if (accordionMap) {
    let accordionMapItems = [];
    $("#accordion-map ul li h2").each(function (i) {
      let layerId = $(this).attr("id");
      let visible = $(this).attr("visible");
      let layerType = $(this).attr("type");

      accordionMapItems.push({
        id: "accordion-map-heading-" + layerId,
        triggerEl: document.querySelector("#accordion-map-heading-" + layerId),
        targetEl: document.querySelector("#accordion-map-body-" + layerId),
        active: false,
      });

      if (layerType == "tms") {
        let layer = tileLayer(
          GEO_HOST +
            `/geoserver/gwc/service/wmts?service=WMTS&request=GetTile&tilematrixset=WebMercatorQuad&TileMatrix={z}&TileCol={x}&TileRow={y}&layer=mapfra:${layerId}&format=image/png`,
          {
            tms: false,
            id: layerId,
          }
        );
        datalayers[layerId] = layer;
        layer.on("click", function (evt) {
          console.log(evt);
        });
        if (visible == "True") {
          datalayers[layerId].addTo(mapViewer.navMap);
        }
        mapViewer.layerControl.addOverlay(datalayers[layerId], layerId);
      } else if (layerType == "wms") {
        let layer = tileLayer.wms(GEO_HOST + "/geoserver/wms?", {
          layers: "mapfra:" + layerId,
          format: "image/png",
          transparent: true,
          id: layerId,
          tiled: true,
        });
        datalayers[layerId] = layer;
        layer.on("click", function (evt) {
          console.log(evt);
        });
        if (visible == "True") {
          datalayers[layerId].addTo(mapViewer.navMap);
        }
        mapViewer.layerControl.addOverlay(datalayers[layerId], layerId);
      } else if (layerType == "wfs") {
        $.getJSON(
          GEO_HOST +
            "/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=mapfra:" +
            layerId +
            "&outputFormat=application/json",
          function (jsondata) {
            datalayers[layerId] = customPolygon(jsondata, {
              id: layerId,
              attributes: { id: "fid" },
              style: dataStyles[layerId],
              onEachFeature: function (feature, layer) {
                layer.bindTooltip(feature.properties.name, {
                  permanent: true,
                  direction: "center",
                  className: "layer-labels",
                });
              },
            });

            datalayers[layerId].on("click", function (e) {
              let tab = tabs.getActiveTab();
              if (
                (tab.id == "chart" &&
                  $("input[name='radio-chart']:checked").val() == "ndvi") ||
                (tab.id == "table" &&
                  $("input[name='radio-table']:checked").val() == "ndvi")
              ) {
                // Pass event to map click event to show the NDVI profile
                return;
              }
              L.DomEvent.stopPropagation(e);
              // Select the clicked feature
              let id = e.sourceTarget.feature.properties.fid;

              // Hide the summary drawer
              if (drawerSummary[layerId]) {
                drawerSummary[layerId].hide();
              }

              datalayers[layerId].selectFeature(id, true);

              if (tab.id == "chart") {
                positionMarker = L.marker(e.latlng, aoiMarkerOptions);
                datalayers["aoiPoint"].clearLayers();
                datalayers["aoiPoint"].addLayer(positionMarker);
                positionMarker.bindPopup(
                  `
                <h2 class="mb-2 text-lg font-bold text-gray-900">${
                  e.sourceTarget.feature.properties.name
                }</h2>
                <p class="!mt-0 inline-flex text-normal font-sm text-gray-600">
                <svg class="w-6 h-6 mx-2 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"/>
                </svg>
                ${Math.abs(e.latlng.lat).toFixed(5)}&deg;${
                    e.latlng.lat < 0 ? "S" : "N"
                  } ${Math.abs(e.latlng.lng).toFixed(5)}&deg;${
                    e.latlng.lng < 0 ? "E" : "W"
                  }</p>
                <div class="inline-flex rounded-md shadow-sm" role="group">
                  <button type="button" class="btn-zoom-in px-2 py-1 text-xs font-medium text-center inline-flex items-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300">
                    <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m21 21-3.5-3.5M10 7v6m-3-3h6m4 0a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
                    </svg>
                    Zoom to
                  </button>
                  <button type="button" class="btn-summary ml-2 px-2 py-1 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300">
                    <svg class="w-6 h-6 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v15a1 1 0 0 0 1 1h15M8 16l2.5-5.5 3 3L17.273 7 20 9.667"/>
                    </svg>
                    Analysis
                  </button>
                </div>
                `
                );
                positionMarker.openPopup();
                $(".btn-summary").on("click", function () {
                  let coordinateText = `${Math.abs(e.latlng.lat).toFixed(
                    5
                  )}&deg;${e.latlng.lat < 0 ? "S" : "N"} ${Math.abs(
                    e.latlng.lng
                  ).toFixed(5)}&deg;${e.latlng.lng < 0 ? "E" : "W"}`;

                  let fid = e.sourceTarget.feature.properties.fid;
                  onShowSummary(fid, layerId, coordinateText);
                  positionMarker.closePopup();
                  datalayers["aoiPoint"].clearLayers();
                });
                $(".btn-zoom-in").on("click", function () {
                  onZoomToProvince(e);
                });
                $(".btn-analysis").on("click", function () {
                  onShowAnalysis(e);
                  positionMarker.closePopup();
                  datalayers["aoiPoint"].clearLayers();
                });
              }
            });

            if (visible == "True") {
              datalayers[layerId].addTo(mapViewer.navMap);
            }

            mapViewer.layerControl.addOverlay(datalayers[layerId], layerId);
          }
        );
        $("#legend-div-" + layerId).css(
          "background-color",
          dataStyles[layerId].fillcolor
        );
        $("#legend-div-" + layerId).css(
          "border",
          `${dataStyles[layerId].weight}px solid ${dataStyles[layerId].color}`
        );
      } else if (layerType == "cog") {
        var url_to_geotiff_file = `https://cog-mafra.s3.amazonaws.com/raster/${layerId}.tif`;
        parseGeoraster(url_to_geotiff_file).then((georaster) => {
          var layer = new GeoRasterLayer({
            georaster: georaster,
            pixelValuesToColorFn: (values) => {
              const classVal = values[0];
              return dataStyles.landcover[classVal];
            },
            opacity: 1,
            resolution: 64,
          });
          datalayers[layerId] = layer;
          layer.on("click", function (evt) {
            console.log(evt);
          });
          if (visible == "True") {
            datalayers[layerId].addTo(mapViewer.navMap);
          }
          mapViewer.layerControl.addOverlay(datalayers[layerId], layerId);
        });
      } else {
        datalayers[layerId] = new L.FeatureGroup();
        if (visible == "True") {
          datalayers[layerId].addTo(mapViewer.navMap);
        }
        mapViewer.layerControl.addOverlay(datalayers[layerId], layerId);
      }

      $("#btn-zoom-" + layerId).on("click", function () {
        $.get(
          GEO_HOST +
            `/geoserver/wms?ervice=WMS&version=1.1.1&request=DescribeLayer&layers=mapfra:${layerId}&outputFormat=application/json`,
          function (response) {
            console.log(response);
          }
        );
      });

      $("#opacity-range-" + layerId).on("input", function () {
        datalayers[layerId].setOpacity($(this).val() / 100);
      });

      $("#select-" + layerId).on("change", function () {
        datalayers[layerId].selectFeature(+$(this).val(), true);
      });

      $("#chart-select-" + layerId).on("change", function () {
        datalayers[layerId].selectFeature(+$(this).val(), true);
        onShowSummary($(this).val(), layerId, undefined);
      });
    });

    // options with default values
    const mapAccordionOptions = {
      alwaysOpen: true,
      activeClasses:
        "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white",
      inactiveClasses: "text-gray-500 dark:text-gray-400",
      onOpen: (item) => {},
      onClose: (item) => {},
      onToggle: (item) => {},
    };

    // instance options object
    const instanceMapOptions = {
      id: "accordion-map",
      override: true,
    };

    /*
     * accordionEl: HTML element (required)
     * accordionItems: array of accordion item objects (required)
     * options (optional)
     * instanceOptions (optional)
     */
    const mapAccordion = new Accordion(
      accordionMap,
      accordionMapItems,
      mapAccordionOptions,
      instanceMapOptions
    );

    // open accordion item based on id
    // mapAccordion.open("accordion-map-heading-ndvi");

    $(".checkbox-layer").on("click", function () {
      if ($(this).is(":checked")) {
        datalayers[$(this).val()].addTo(mapViewer.navMap);
        // datalayers[$(this).val()].bringToFront();
      } else {
        mapViewer.navMap.removeLayer(datalayers[$(this).val()]);
        if (drawerSummary[$(this).val()]) {
          drawerSummary[$(this).val()].hide();
        }
      }
    });
  }

  if (accordionChart) {
    let accordionChartItems = [];
    for (let i = 0; i < accordionChart.children.length; i++) {
      if (accordionChart.children[i].tagName.toUpperCase() == "H2") {
        let layerId = accordionChart.children[i].id;

        let layerName = $(accordionChart.children[i]).attr("name");
        let visible = $(accordionChart.children[i]).attr("visible");
        let layerType = $(accordionChart.children[i]).attr("type");

        accordionChartItems.push({
          id: "accordion-chart-heading-" + layerId,
          triggerEl: document.querySelector(
            "#accordion-chart-heading-" + layerId
          ),
          targetEl: document.querySelector("#accordion-chart-body-" + layerId),
          active: false,
        });
        dataCharts[layerId] = null;
      }
    }

    const chartAccordionOptions = {
      alwaysOpen: true,
      activeClasses:
        "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white",
      inactiveClasses: "text-gray-500 dark:text-gray-400",
      onOpen: (item) => {},
      onClose: (item) => {},
      onToggle: (item) => {},
    };

    // instance options object
    const instanceChartOptions = {
      id: "accordion-chart",
      override: true,
    };

    const chartAccordion = new Accordion(
      accordionChart,
      accordionChartItems,
      chartAccordionOptions,
      instanceChartOptions
    );

    // open accordion item based on id
    chartAccordion.open("accordion-chart-heading-ndvi");

    $("input[name='radio-chart']").on("change", function () {
      datalayers["aoiPoint"].clearLayers();
      if (
        !document
          .getElementById("home:chart_viewer")
          .classList.contains("hidden")
      ) {
        document.getElementById("home:chart_viewer").classList.add("hidden");
      }
    });
  }

  if (accordionTable) {
    let accordionTableItems = [];
    for (let i = 0; i < accordionTable.children.length; i++) {
      if (accordionTable.children[i].tagName.toUpperCase() == "H2") {
        let layerId = accordionTable.children[i].id;

        let layerName = $(accordionTable.children[i]).attr("name");
        let visible = $(accordionTable.children[i]).attr("visible");
        let layerType = $(accordionTable.children[i]).attr("type");

        accordionTableItems.push({
          id: "accordion-table-heading-" + layerId,
          triggerEl: document.querySelector(
            "#accordion-table-heading-" + layerId
          ),
          targetEl: document.querySelector("#accordion-table-body-" + layerId),
          active: false,
        });
        dataTables[layerId] = null;
        createNDVIWebixTable(layerId);
      }
    }

    const tableAccordionOptions = {
      alwaysOpen: true,
      activeClasses:
        "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white",
      inactiveClasses: "text-gray-500 dark:text-gray-400",
      onOpen: (item) => {},
      onClose: (item) => {},
      onToggle: (item) => {},
    };

    // instance options object
    const instanceTableOptions = {
      id: "accordion-table",
      override: true,
    };

    const tableAccordion = new Accordion(
      accordionTable,
      accordionTableItems,
      tableAccordionOptions,
      instanceTableOptions
    );

    // open accordion item based on id
    tableAccordion.open("accordion-table-heading-ndvi");
  }
});

const getFeatureInfoUrl = function (map, layerName, evt) {
  let size = map.getSize();
  let latlng = evt.latlng;
  let point = map.latLngToContainerPoint(latlng, map.getZoom());

  let height = size.y;
  height = Math.round(size.y);
  let width = size.x;
  width = Math.round(width);

  let params = {
    request: "GetFeatureInfo",
    service: "WMS",
    srs: "EPSG:4326",
    styles: "",
    transparent: "true",
    version: "1.1.1",
    format: "image/png",
    bbox: map.getBounds().toBBoxString(),
    height: Math.round(height),
    width: Math.round(width),
    layers: layerName,
    query_layers: layerName,
    info_format: "application/json",
  };

  params[params.version === "1.3.0" ? "i" : "x"] = Math.round(point.x);
  params[params.version === "1.3.0" ? "j" : "y"] = Math.round(point.y);

  return (
    GEO_HOST +
    "/geoserver/wms" +
    L.Util.getParamString(params, GEO_HOST + "/geoserver/wms", true)
  );
};

const getFeatureInfoUrl1 = function (map, layerName, x, y) {
  let bounds = map.getBounds().toBBoxString();
  let size = map.getSize();
  return (
    GEO_HOST +
    `/geoserver/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&QUERY_LAYERS=${layerName}&LAYERS=${layerName}&INFO_FORMAT=application/json&X=${Math.floor(
      x
    )}&Y=${Math.floor(y)}&SRS=EPSG:4326&WIDTH=${Math.floor(
      size.x
    )}&HEIGHT=${Math.floor(size.y)}&BBOX=${bounds}`
  );
};

const chartDataExists = () => {
  for (const value of Object.values(dataCharts)) {
    return value !== null;
  }
};

const TableDataExists = () => {
  for (const value of Object.values(dataTables)) {
    return value !== null;
  }
};

const formatNDVITabularData = (data) => {
  let table = `
    <table class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
      <thead class="bg-gray-100 dark:bg-gray-700">
        <tr>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">Dekad</th>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">10-th Percentile</th>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">50-th Percentile</th>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">90-th Percentile</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
      `;
  let rows = "";
  let col1 = data.series.find((item) => item.name === "10-th Percentile").data;
  let col2 = data.series.find((item) => item.name === "50-th Percentile").data;
  let col3 = data.series.find((item) => item.name === "90-th Percentile").data;

  data.categories.forEach((category, idx) => {
    rows += `
      <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
        <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div class="text-base font-semibold text-gray-900 dark:text-white">${category}</div>
        </td>
        <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
        ${col1[idx]}
        </td>
        <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
        ${col2[idx]}
        </td>
        <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
        ${col3[idx]}
        </td>
      </tr>
    `;
  });
  table += `${rows}
    </tbody>
    </table>
  `;
  return table;
};

const formatNDVIData = (data) => {
  let column0 = data.categories;
  let column1 = data.series.find(
    (item) => item.name === "10-th Percentile"
  ).data;
  let column2 = data.series.find(
    (item) => item.name === "50-th Percentile"
  ).data;
  let column3 = data.series.find(
    (item) => item.name === "90-th Percentile"
  ).data;

  let formatted_data = [];
  column0.forEach((record, idx) => {
    formatted_data.push({
      id: idx + 1,
      col0: record,
      col1: column1[idx],
      col2: column2[idx],
      col3: column3[idx],
    });
  });
  return formatted_data;
};

const createNDVIWebixTable = (divID) => {
  var pager = {
    view: "pager",
    css: "!w-full",
    id: "pager:" + divID,
    size: 10,
    group: 5,
  };
  webix.ui({
    css: "!w-full",
    id: "component:" + divID,
    height: $("#table_viewer_" + divID).height(),
    rows: [
      {
        view: "datatable",
        id: "grid:" + divID,
        footer: false,
        scroll: "y",
        gravity: 3,
        tooltip: true,
        width: $("#table_viewer_" + divID).width() - 10,
        select: "row",
        css: "!w-full bg-white dark:bg-gray-800 text-gray-800 dark:text-white",
        columns: [
          {
            id: "col0",
            map: "col0",
            header: "Dekad",
            width: 70,
            sort: "int",
            css: "bg-white dark:bg-gray-800 text-gray-800 dark:text-white",
          },
          {
            id: "col1",
            map: "col1",
            header: "10-th Percentile",
            width: 150,
            fillspace: true,
            css: "bg-white dark:bg-gray-800 text-gray-800 dark:text-white",
          },
          {
            id: "col2",
            map: "col2",
            header: "50-th Percentile",
            width: 150,
            fillspace: true,
            css: "bg-white dark:bg-gray-800 text-gray-800 dark:text-white",
          },
          {
            id: "col3",
            map: "col3",
            header: "90-th Percentile",
            width: 150,
            fillspace: true,
            css: "bg-white dark:bg-gray-800 text-gray-800 dark:text-white",
          },
        ],
        data: [],
        pager: "pager:" + divID,
        on: {
          "data->onStoreUpdated": function () {
            this.eachColumn(function (id) {
              this.markSorting(id, false);
            });
          },
        },
      },
      pager,
    ],
    container: "table_viewer_" + divID,
  });
};

const showMap = () => {
  // Hide chart & table panels
  document.getElementById("home:chart_viewer").classList.add("hidden");
  document.getElementById("home:table_viewer").classList.add("hidden");
  mapViewer.navMap.invalidateSize();
};

const showChart = () => {
  let tab = tabs.getActiveTab();
  // Show/Hide table panel
  if (
    tab.id == "chart" &&
    chartDataExists() &&
    dataCharts[$("input[name='radio-chart']:checked").val()]
  ) {
    document.getElementById("home:chart_viewer").classList.remove("hidden");
  } else {
    document.getElementById("home:chart_viewer").classList.add("hidden");
  }
  document.getElementById("home:table_viewer").classList.add("hidden");
  mapViewer.navMap.invalidateSize();
};

const showTable = () => {
  let tab = tabs.getActiveTab();
  // Hide chart panel
  if (tab.id == "table" && dataTables[$("input[name='radio-table']").val()]) {
    document.getElementById("home:table_viewer").classList.remove("hidden");
  } else {
    document.getElementById("home:table_viewer").classList.add("hidden");
  }
  document.getElementById("home:chart_viewer").classList.add("hidden");
  mapViewer.navMap.invalidateSize();
};

const showNDVIChart = (e, tab) => {
  positionMarker = L.circleMarker(e.latlng, aoiMarkerOptions);
  $.get(
    getFeatureInfoUrl(mapViewer.navMap, "mapfra:ndvi", e),
    function (response) {
      if (
        response?.features?.length > 0 &&
        response.features[0].properties.GRAY_INDEX > 0
      ) {
        $.get(
          "/api/v3/ndvi/profile/" + response.features[0].properties.GRAY_INDEX,
          function (data) {
            datalayers["aoiPoint"].clearLayers();
            datalayers["aoiPoint"].addLayer(positionMarker);

            showChart();

            dataCharts["ndvi"] = getLineChartOptions(
              data,
              $("#chart_viewer_ndvi").height() - 30,
              ["#bae4b3", "#006d2c", "#bae4b3"],
              true
            );

            if (!lineChart) {
              lineChart = new ApexCharts(
                document.querySelector("#chart_viewer_ndvi"),
                dataCharts["ndvi"]
              );
              lineChart.render();
            } else {
              lineChart.updateOptions(dataCharts["ndvi"]);
            }

            positionMarker.bindPopup(
              ` <h3 class="text-base font-semibold">Feature Information</h3>
                <div class="flex items-center justify-end col-span-2">
                    <span>${Math.abs(e.latlng.lng.toFixed(5))}${
                e.latlng.lng < 0 ? "W" : "E"
              } ${Math.abs(e.latlng.lat.toFixed(5))}${
                e.latlng.lat < 0 ? "S" : "N"
              }
                </span>
                  <button type="button" class="w-7 feature-info-location">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                  </button>
                </div>
                `
            );
          }
        );
      } else {
        positionMarker.bindPopup(
          `
            <div>
              <h3 class="text-base font-semibold">Feature Information</h3>
              <div class="w-full font-medium">No data is available here - try another location.
              </div>
              <div class="flex items-center justify-end col-span-2">
                  <span>${Math.abs(e.latlng.lng.toFixed(5))}${
            e.latlng.lng < 0 ? "W" : "E"
          } ${Math.abs(e.latlng.lat.toFixed(5))}${
            e.latlng.lat < 0 ? "S" : "N"
          }</span>
                  <button type="button" class="w-7 feature-info-location">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                  </button>
                </div>
            </div>
            `
        );

        $(".feature-info-location").on("click", function () {
          console.log(this);
        });
      }
    }
  ).fail(function () {});
};

const showNDVITable = (e, tab) => {
  positionMarker = L.circleMarker(e.latlng, aoiMarkerOptions);
  $.get(
    getFeatureInfoUrl(mapViewer.navMap, "mapfra:ndvi", e),
    function (response) {
      if (
        response?.features?.length > 0 &&
        response.features[0].properties.GRAY_INDEX > 0
      ) {
        $.get(
          "/api/v3/ndvi/profile/" + response.features[0].properties.GRAY_INDEX,
          function (data) {
            datalayers["aoiPoint"].clearLayers();
            datalayers["aoiPoint"].addLayer(positionMarker);

            showTable();

            dataTables["ndvi"] = formatNDVIData(data);

            $$("grid:ndvi").clearAll();
            $$("grid:ndvi").parse(dataTables["ndvi"]);

            positionMarker.bindPopup(
              ` <h3 class="text-base font-semibold">Feature Information</h3>
                <div class="flex items-center justify-end col-span-2">
                    <span>${Math.abs(e.latlng.lng.toFixed(5))}${
                e.latlng.lng < 0 ? "W" : "E"
              } ${Math.abs(e.latlng.lat.toFixed(5))}${
                e.latlng.lat < 0 ? "S" : "N"
              }
                </span>
                  <button type="button" class="w-7 feature-info-location">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                  </button>
                </div>
                `
            );
          }
        );
      } else {
        positionMarker.bindPopup(
          `
            <div>
              <h3 class="text-base font-semibold">Feature Information</h3>
              <div class="w-full font-medium">No data is available here - try another location.
              </div>
              <div class="flex items-center justify-end col-span-2">
                  <span>${Math.abs(e.latlng.lng.toFixed(5))}${
            e.latlng.lng < 0 ? "W" : "E"
          } ${Math.abs(e.latlng.lat.toFixed(5))}${
            e.latlng.lat < 0 ? "S" : "N"
          }</span>
                  <button type="button" class="w-7 feature-info-location">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                  </button>
                </div>
            </div>
            `
        );

        $(".feature-info-location").on("click", function () {
          console.log(this);
        });
      }
    }
  ).fail(function () {});
};

const onShowSummary = (fid, layerId, coordinateText = undefined) => {
  // Hide all drawers
  for (const property in drawerSummary) {
    drawerSummary[property].hide();
  }
  // Show progressbar
  document.getElementById("loadingModal").classList.remove("hidden");
  $.get(`./api/v3/summary/${layerId}/${fid}`, function (response) {
    drawerSummary[layerId] = initSummaryDrawer(
      "drawer-right-summary",
      coordinateText,
      response
    );
    drawerSummary[layerId].show();
    document.getElementById("loadingModal").classList.add("hidden");
  });

  $(".drawer-right-summary-toggle").prop("checked", false);

  $(".drawer-right-summary-toggle").on("change", function () {
    togglePosition($(this).is(":checked"));
  });
};

const onZoomToProvince = (e) => {
  mapViewer.navMap.fitBounds(e.sourceTarget.getBounds());
  console.log("Zoom to province!");
};

const onShowAnalysis = (e) => {
  // mapViewer.navMap.fitBounds(e.target.getBounds());
  console.log("Analysis!");
};

const togglePosition = (show = true) => {
  if (show) {
    datalayers["aoiPoint"].addLayer(positionMarker);
  } else {
    datalayers["aoiPoint"].clearLayers();
  }
};
