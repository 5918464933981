export const aoiMarkerOptions = {
  radius: 8,
  fillColor: "#ff7800",
  color: "#000",
  weight: 1,
  opacity: 1,
  fillOpacity: 0.8,
};

export const aoiPolyOptions = {
  fillColor: "#ff7800",
  color: "#000",
  weight: 1,
  opacity: 1,
  fillOpacity: 0.8,
  stroke: true,
};

export const observationStyle = (feature) => {
  return {
    weight: 2,
    opacity: 1,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.8,
    fillColor:
      feature.properties.ob_type == "Interpretação no campo"
        ? "blue"
        : feature.properties.ob_type ==
          "Levantamento de campo, ponto visível, <= 100 m"
        ? "red"
        : "green",
  };
};

const provinceStyle = {
  weight: 3,
  opacity: 1,
  color: "#3388ff",
  fillOpacity: 0,
  fillColor: "#eee",
  colorSelected: ["blue"],
};

const districtStyle = {
  weight: 2,
  opacity: 1,
  color: "#ff7800",
  fillOpacity: 0,
  fillColor: "#eee",
  colorSelected: ["red"],
};

const subdistrictStyle = {
  weight: 1,
  opacity: 1,
  color: "#000",
  fillOpacity: 0,
  fillColor: "#eee",
  colorSelected: ["red"],
};

const strataStyle = {
  weight: 1,
  opacity: 1,
  color: "#78ff00",
  fillOpacity: 0,
  fillColor: "#eee",
  colorSelected: ["red"],
};

const landCoverStyle = {
  0: "rgba(255, 255, 255, 0)",
  1: "#df73ff",
  3: "#ffff00",
  4: "#ff0000",
  5: "#a8a800",
  6: "#00734c",
  7: "#732600",
  8: "#98e600",
  9: "#00ffc5",
  10: "#3399FF",
};

export const dataStyles = {
  province: provinceStyle,
  district: districtStyle,
  subdistrict: subdistrictStyle,
  strata: strataStyle,
  landcover: landCoverStyle,
};
