import ApexCharts from "apexcharts";
import { thousandSeperator } from "./utils";

const getMainChartOptions = () => {
  let mainChartColors = {};

  if (document.documentElement.classList.contains("dark")) {
    mainChartColors = {
      borderColor: "#374151",
      labelColor: "#9CA3AF",
      opacityFrom: 0,
      opacityTo: 0.15,
    };
  } else {
    mainChartColors = {
      borderColor: "#F3F4F6",
      labelColor: "#6B7280",
      opacityFrom: 0.45,
      opacityTo: 0,
    };
  }

  return {
    chart: {
      height: 420,
      type: "area",
      fontFamily: "Inter, sans-serif",
      foreColor: mainChartColors.labelColor,
      toolbar: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        enabled: true,
        opacityFrom: mainChartColors.opacityFrom,
        opacityTo: mainChartColors.opacityTo,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    grid: {
      show: true,
      borderColor: mainChartColors.borderColor,
      strokeDashArray: 1,
      padding: {
        left: 35,
        bottom: 15,
      },
    },
    series: [
      {
        name: "Revenue",
        data: [6356, 6218, 6156, 6526, 6356, 6256, 6056],
        color: "#1A56DB",
      },
      {
        name: "Revenue (previous period)",
        data: [6556, 6725, 6424, 6356, 6586, 6756, 6616],
        color: "#FDBA8C",
      },
    ],
    markers: {
      size: 5,
      strokeColors: "#ffffff",
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    xaxis: {
      categories: [
        "01 Feb",
        "02 Feb",
        "03 Feb",
        "04 Feb",
        "05 Feb",
        "06 Feb",
        "07 Feb",
      ],
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
      },
      axisBorder: {
        color: mainChartColors.borderColor,
      },
      axisTicks: {
        color: mainChartColors.borderColor,
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: mainChartColors.borderColor,
          width: 1,
          dashArray: 10,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
        formatter: function (value) {
          return "$" + value;
        },
      },
    },
    legend: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      labels: {
        colors: [mainChartColors.labelColor],
      },
      itemMargin: {
        horizontal: 10,
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          xaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };
};

if (document.getElementById("main-chart")) {
  const chart = new ApexCharts(
    document.getElementById("main-chart"),
    getMainChartOptions()
  );
  chart.render();

  // init again when toggling dark mode
  document.addEventListener("dark-mode", function () {
    chart.updateOptions(getMainChartOptions());
  });
}

if (document.getElementById("new-products-chart")) {
  const options = {
    colors: ["#1A56DB", "#FDBA8C"],
    series: [
      {
        name: "Quantity",
        color: "#1A56DB",
        data: [
          { x: "01 Feb", y: 170 },
          { x: "02 Feb", y: 180 },
          { x: "03 Feb", y: 164 },
          { x: "04 Feb", y: 145 },
          { x: "05 Feb", y: 194 },
          { x: "06 Feb", y: 170 },
          { x: "07 Feb", y: 155 },
        ],
      },
    ],
    chart: {
      type: "bar",
      height: "140px",
      fontFamily: "Inter, sans-serif",
      foreColor: "#4B5563",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "90%",
        borderRadius: 3,
      },
    },
    tooltip: {
      shared: false,
      intersect: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ["transparent"],
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      floating: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  };

  const chart = new ApexCharts(
    document.getElementById("new-products-chart"),
    options
  );
  chart.render();
}

const getVisitorsChartOptions = () => {
  let visitorsChartColors = {};

  if (document.documentElement.classList.contains("dark")) {
    visitorsChartColors = {
      fillGradientShade: "dark",
      fillGradientShadeIntensity: 0.45,
    };
  } else {
    visitorsChartColors = {
      fillGradientShade: "light",
      fillGradientShadeIntensity: 1,
    };
  }

  return {
    series: [
      {
        name: "Visitors",
        data: [500, 590, 600, 520, 610, 550, 600],
      },
    ],
    labels: [
      "01 Feb",
      "02 Feb",
      "03 Feb",
      "04 Feb",
      "05 Feb",
      "06 Feb",
      "07 Feb",
    ],
    chart: {
      type: "area",
      height: "305px",
      fontFamily: "Inter, sans-serif",
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: visitorsChartColors.fillGradientShade,
        shadeIntensity: visitorsChartColors.fillGradientShadeIntensity,
      },
    },
    plotOptions: {
      area: {
        fillTo: "end",
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#1A56DB",
      },
    },
    tooltip: {
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
  };
};

const getSignupsChartOptions = () => {
  let signupsChartColors = {};

  if (document.documentElement.classList.contains("dark")) {
    signupsChartColors = {
      backgroundBarColors: [
        "#374151",
        "#374151",
        "#374151",
        "#374151",
        "#374151",
        "#374151",
        "#374151",
      ],
    };
  } else {
    signupsChartColors = {
      backgroundBarColors: [
        "#E5E7EB",
        "#E5E7EB",
        "#E5E7EB",
        "#E5E7EB",
        "#E5E7EB",
        "#E5E7EB",
        "#E5E7EB",
      ],
    };
  }

  return {
    series: [
      {
        name: "Users",
        data: [1334, 2435, 1753, 1328, 1155, 1632, 1336],
      },
    ],
    labels: [
      "01 Feb",
      "02 Feb",
      "03 Feb",
      "04 Feb",
      "05 Feb",
      "06 Feb",
      "07 Feb",
    ],
    chart: {
      type: "bar",
      height: "140px",
      foreColor: "#4B5563",
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: false,
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#1A56DB",
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "25%",
        borderRadius: 3,
        colors: {
          backgroundBarColors: signupsChartColors.backgroundBarColors,
          backgroundBarRadius: 3,
        },
      },
      dataLabels: {
        hideOverflowingLabels: false,
      },
    },
    xaxis: {
      floating: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.8,
        },
      },
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };
};

if (document.getElementById("week-signups-chart")) {
  const chart = new ApexCharts(
    document.getElementById("week-signups-chart"),
    getSignupsChartOptions()
  );
  chart.render();

  // init again when toggling dark mode
  document.addEventListener("dark-mode", function () {
    chart.updateOptions(getSignupsChartOptions());
  });
}

const pieChartOptions = (data) => {
  let trafficChannelsChartColors = {};
  let legendLabelColor = "#000";

  if (document.documentElement.classList.contains("dark")) {
    trafficChannelsChartColors = {
      strokeColor: "#1f2937",
    };
    legendLabelColor = "#fff";
  } else {
    trafficChannelsChartColors = {
      strokeColor: "#ffffff",
    };
    legendLabelColor = "#000";
  }

  return {
    series: data.map((dt) => dt.price),
    labels: data.map((dt) => dt.name),
    colors: ["#16BDCA", "#FDBA8C", "#1A56DB"],
    chart: {
      type: "donut",
      height: 400,
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 430,
        options: {
          chart: {
            height: 300,
          },
        },
      },
    ],
    stroke: {
      colors: [trafficChannelsChartColors.strokeColor],
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9,
        },
      },
    },
    tooltip: {
      shared: true,
      followCursor: false,
      fillSeriesColor: false,
      inverseOrder: true,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
      x: {
        show: true,
        formatter: function (_, { seriesIndex, w }) {
          const label = w.config.labels[seriesIndex];
          return label;
        },
      },
      y: {
        formatter: function (value) {
          return value;
        },
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };
};

if (document.getElementById("products-bar-chart-api")) {
  const apiUrl = "/api/product/";
  let dt = [];

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      dt = data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  await fetchData();

  const options = {
    colors: ["#1A56DB", "#FDBA8C"],
    series: [
      {
        name: "Product",
        color: "#1A56DB",
        data: dt.map((product) => ({ x: product.name, y: product.price })),
      },
    ],
    chart: {
      type: "bar",
      height: "420px",
      fontFamily: "Inter, sans-serif",
      foreColor: "#4B5563",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "90%",
        borderRadius: 3,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ["transparent"],
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      floating: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  };

  const chart = new ApexCharts(
    document.getElementById("products-bar-chart-api"),
    options
  );
  chart.render();
}

if (document.getElementById("products-pie-chart-api")) {
  const apiUrl = "/api/product/";
  let dt = [];

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      dt = data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  await fetchData();

  const chart = new ApexCharts(
    document.getElementById("products-pie-chart-api"),
    pieChartOptions(dt)
  );
  chart.render();

  // init again when toggling dark mode
  document.addEventListener("dark-mode", function () {
    chart.updateOptions(pieChartOptions(dt));
  });
}

export const drawPieChart = function (
  data,
  component_id,
  width = 350,
  colors = undefined
) {
  let trafficChannelsChartColors = {};
  let legendLabelColor = "#000";
  if (document.documentElement.classList.contains("dark")) {
    trafficChannelsChartColors = {
      strokeColor: "#1f2937",
    };
    legendLabelColor = "#fff";
  } else {
    trafficChannelsChartColors = {
      strokeColor: "#ffffff",
    };
  }

  var options = {
    series: data.series,
    chart: {
      width: width,
      height: width + 30,
      type: "pie",
      horizontalAlign: "left",
      offsetX: 0,
      offsetY: 0,
    },
    stroke: {
      colors: [trafficChannelsChartColors.strokeColor],
    },
    tooltip: {
      shared: true,
      followCursor: false,
      fillSeriesColor: false,
      inverseOrder: true,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
      x: {
        show: true,
        formatter: function (_, { seriesIndex, w }) {
          const label = w.config.labels[seriesIndex];
          return label;
        },
      },
      y: {
        formatter: function (value) {
          return value;
        },
      },
    },

    labels: data.labels,
    legend: {
      show: true,
      horizontalAlign: "left",
      position: "bottom",
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: legendLabelColor,
        useSeriesColors: false,
      },
    },
  };

  if (colors) {
    options.colors = colors;
  }

  document.querySelector(`#${component_id}`).innerHTML = "";

  var chart = new ApexCharts(
    document.querySelector(`#${component_id}`),
    options
  );
  chart.render();
  return chart;
};

export var drawDonutChart = function (data, component_id, width) {
  var options = {
    series: data.y,
    chart: {
      type: "donut",
      width: width,
    },
    labels: data.x,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  var chart = new ApexCharts(
    document.querySelector("#" + component_id),
    options
  );
  chart.render();
  return chart;
};

export var drawSemiDonutChart = function (data, component_id, width) {
  var options = {
    series: data.y,
    chart: {
      type: "donut",
      width: width,
    },
    labels: data.x,
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
      },
    },
    grid: {
      padding: {
        bottom: -80,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "top",
          },
        },
      },
    ],
  };

  var chart = new ApexCharts(
    document.querySelector("#" + component_id),
    options
  );
  chart.render();
  return chart;
};

export const getLineChartOptions = function (
  data,
  height,
  colors = undefined,
  showTicks = true
) {
  let mainChartColors = {};

  if (document.documentElement.classList.contains("dark")) {
    mainChartColors = {
      borderColor: "#374151",
      labelColor: "#9CA3AF",
      opacityFrom: 0,
      opacityTo: 0.15,
    };
  } else {
    mainChartColors = {
      borderColor: "#F3F4F6",
      labelColor: "#6B7280",
      opacityFrom: 0.45,
      opacityTo: 0,
    };
  }
  var options = {
    series: data.series,
    chart: {
      height: height,
      type: "area",
      fontFamily: "Inter, sans-serif",
      foreColor: mainChartColors.labelColor,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        enabled: true,
        opacityFrom: mainChartColors.opacityFrom,
        opacityTo: mainChartColors.opacityTo,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    grid: {
      show: true,
      borderColor: mainChartColors.borderColor,
      strokeDashArray: 1,
      padding: {
        left: 15,
        bottom: 15,
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: data.categories,
      tickAmount: 10,
      labels: {
        show: showTicks,
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
      },
      axisBorder: {
        color: mainChartColors.borderColor,
      },
      axisTicks: {
        color: mainChartColors.borderColor,
        axisTicks: {
          show: showTicks,
        },
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: mainChartColors.borderColor,
          width: 1,
          dashArray: 10,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
        formatter: function (value) {
          return Math.round(value);
        },
      },
    },
    legend: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      labels: {
        colors: [mainChartColors.labelColor],
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  if (colors) {
    options.colors = colors;
  }

  return options;
};

export const drawBarChart = function (
  data,
  component_id,
  title,
  colors = ["blue"],
  yMin = 0,
  yMax = undefined,
  height = 350
) {
  let trafficChannelsChartColors = {};
  if (document.documentElement.classList.contains("dark")) {
    trafficChannelsChartColors = {
      strokeColor: "#1f2937",
      color: "#ffffff",
    };
  } else {
    trafficChannelsChartColors = {
      strokeColor: "#ffffff",
      color: "#304758",
    };
  }
  var options = {
    series: data.series,
    chart: {
      height: height,
      type: "bar",
      toolbar: {
        show: false,
      },
      events: {
        click: function (chart, w, e) {},
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
        borderRadius: 0,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      position: "center",
      enabled: true,
      formatter: function (val) {
        return thousandSeperator(val);
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: [trafficChannelsChartColors.color],
      },
    },
    xaxis: {
      categories: data.x,
      position: "bottom",
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      labels: {
        style: {
          colors: new Array(data.x.length).fill(
            trafficChannelsChartColors.color
          ),
        },
      },
    },
    yaxis: {
      min: yMin,
      max: yMax,
      decimalsInFloat: 0,
      forceNiceScale: true,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      title: {
        text: "Area (ha)",
        style: {
          color: trafficChannelsChartColors.color,
          fontWeight: 500,
        },
      },
      labels: {
        show: true,
        style: {
          colors: [trafficChannelsChartColors.color],
        },
        formatter: function (val) {
          return thousandSeperator(Math.round(val));
        },
      },
    },
    title: {
      text: title,
      floating: true,
      offsetY: 0,
      align: "center",
      style: {
        color: trafficChannelsChartColors.color,
      },
    },
  };

  let chart = new ApexCharts(
    document.querySelector(`#${component_id}`),
    options
  );
  chart.render();
  return chart;
};

export const drawStackedBarChart = function (
  data,
  component_id,
  height = 450,
  xaxisTitle = "Strata",
  yaxisTitle = "Number of plots"
) {
  let trafficChannelsChartColors = {};
  if (document.documentElement.classList.contains("dark")) {
    trafficChannelsChartColors = {
      strokeColor: "#1f2937",
      color: "#ffffff",
    };
  } else {
    trafficChannelsChartColors = {
      strokeColor: "#ffffff",
      color: "#304758",
    };
  }
  var options = {
    series: data.series,
    chart: {
      type: "bar",
      height: height,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: data.x,
      decimalsInFloat: 0,
      forceNiceScale: true,
      labels: {
        style: {
          colors: new Array(20).fill(trafficChannelsChartColors.color),
        },
      },
      title: {
        text: yaxisTitle,
        style: {
          color: trafficChannelsChartColors.color,
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      decimalsInFloat: 0,
      forceNiceScale: true,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      title: {
        text: xaxisTitle,
        style: {
          color: trafficChannelsChartColors.color,
          fontWeight: 500,
        },
      },
      labels: {
        show: true,
        style: {
          colors: [trafficChannelsChartColors.color],
        },
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "rgb(0, 227, 150)",
      "rgb(0, 143, 251)",
      "rgb(255, 69, 96)",
      "rgb(254, 176, 25)",
    ],
    legend: {
      position: "top",
      horizontalAlign: "left",
      labels: {
        colors: trafficChannelsChartColors.color,
        useSeriesColors: false,
      },
    },
  };

  let chart = new ApexCharts(
    document.querySelector(`#${component_id}`),
    options
  );
  chart.render();
  return chart;
};

export const drawRadarChart = function (data, component_id, height = 300) {
  let trafficChannelsChartColors = {};
  if (document.documentElement.classList.contains("dark")) {
    trafficChannelsChartColors = {
      strokeColor: "#1f2937",
      color: "#ffffff",
      fill: ["#9ca3af", "#1f2937"],
    };
  } else {
    trafficChannelsChartColors = {
      strokeColor: "#ffffff",
      color: "#304758",
      fill: ["#f8f8f8", "#fff"],
    };
  }
  var options = {
    series: data.series,
    chart: {
      height: height,
      type: "radar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
      },
    },
    plotOptions: {
      radar: {
        size: 140,
        polygons: {
          strokeColors: "#e9e9e9",
          fill: {
            colors: trafficChannelsChartColors.fill,
          },
        },
      },
    },
    colors: ["#FF4560"],
    markers: {
      size: 4,
      colors: ["#fff"],
      strokeColor: "#FF4560",
      strokeWidth: 2,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: data.x,
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          colors: [trafficChannelsChartColors.color],
        },
        formatter: function (val, i) {
          if (i % 2 === 0) {
            return thousandSeperator(val);
          } else {
            return "";
          }
        },
      },
    },
  };

  let chart = new ApexCharts(
    document.querySelector(`#${component_id}`),
    options
  );
  chart.render();
  return chart;
};

export const drawFunnelChart = function (
  data,
  component_id,
  yMin = undefined,
  yMax = 500,
  height = 350
) {
  let trafficChannelsChartColors = {};
  if (document.documentElement.classList.contains("dark")) {
    trafficChannelsChartColors = {
      strokeColor: "#1f2937",
      color: "#ffffff",
    };
  } else {
    trafficChannelsChartColors = {
      strokeColor: "#ffffff",
      color: "#304758",
    };
  }
  var options = {
    series: data.series,
    chart: {
      height: height,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex];
      },
      dropShadow: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: "80%",
        isFunnel: true,
      },
    },
    yaxis: {
      min: yMin,
      max: yMax,
      decimalsInFloat: 0,
      forceNiceScale: true,
    },
    xaxis: {
      categories: data.x,
    },
    legend: {
      show: false,
    },
  };

  let chart = new ApexCharts(
    document.querySelector(`#${component_id}`),
    options
  );
  chart.render();
  return chart;
};
