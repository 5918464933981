const tileServers = [
  {
    id: "googleSatellite",
    name: "Google Satellite",
    isImage: true,
    tileURL: "http://mt1.google.com/vt/lyrs=s&hl=en&&x={x}&y={y}&z={z}",
    attribution: "Google",
  },
  {
    id: "googleStreets",
    name: "Google Streets",
    isImage: false,
    tileURL: "http://mt1.google.com/vt/lyrs=m&hl=en&&x={x}&y={y}&z={z}",
    attribution: "Google",
  },
  {
    id: "googleTerrain",
    name: "Google Terrain",
    isImage: false,
    tileURL: "http://mt1.google.com/vt/lyrs=p&hl=en&&x={x}&y={y}&z={z}",
    attribution: "",
  },
  {
    id: "arcgisImagery",
    name: "ArcGIS Imagery",
    isImage: true,
    tileURL:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    attribution: "",
  },
  {
    id: "arcgisStreets",
    name: "ArcGIS Streets",
    isImage: false,
    tileURL:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_streets_Map/MapServer/tile/{z}/{y}/{x}",
    attribution: "",
  },
  {
    id: "arcgisTerrain",
    name: "ArcGIS Topographic",
    isImage: false,
    tileURL:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_topo_Map/MapServer/tile/{z}/{y}/{x}",
    attribution:
      "&copy; <a href='https://www.esri.com'  target='_blank'>ESRI</a>",
  },
  {
    id: "openStreetMap",
    name: "OpenStreetMap",
    isImage: false,
    tileURL: "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright"  target="_blank">OpenStreetMap</a> contributors',
  },
];

var _basemaps = new Array();
var _basemapURLs = {};
var _imageBasemaps = new Array();

for (let item of tileServers) {
  _basemapURLs[item.id] = item;
  _basemaps.push({
    id: item.id,
    value: item.name,
  });
  if (item.isImage) {
    _imageBasemaps.push(item.id);
  }
}

_basemaps.push({
  id: "none",
  value: "None",
});

export const basemaps = _basemaps;
export const basemapURLs = _basemapURLs;
export const imageBasemaps = _imageBasemaps;
