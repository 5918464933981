import { GeoJSON } from "leaflet";
var CustomPoly = GeoJSON.extend({
  options: {
    attributes: {},
    style: {},
  },
  _defaultAttributes: {
    id: "id",
    value: "value",
  },
  _defaultStyle: {
    color: "lightgray",
    colorSelected: ["red"],
    fillColor: "lightgray",
    fillColorNull: "lightgray",
    weight: 2,
    weightSelected: 2,
    opacity: 1.0,
    fillOpacity: 1.0,
  },

  _selectedIds: [],
  _colors: null,
  _limits: null,

  _validateAttributes: function () {
    let attributes = this.options.attributes;
    if ((attributes == null) | (attributes == undefined))
      attributes = Object.assign({}, this._defaultAttributes);
    else {
      for (let key in this._defaultAttributes) {
        if (!(key in attributes))
          attributes[key] = this._defaultAttributes[key];
      }
    }
  },

  _validateStyles: function () {
    let style = this.options.style;
    if (typeof style.colorSelected == "string")
      style.colorSelected = [style.colorSelected];

    if ((style == null) | (style == undefined))
      style = Object.assign({}, this._defaultStyle);
    else {
      for (let key in this._defaultStyle) {
        if (!(key in style)) style[key] = this._defaultStyle[key];
      }
    }
  },
  _getColor(value) {
    let i = 0;
    while (value > this._limits[i] && i + 2 < this._limits.length) {
      i++;
    }
    return this._colors[i];
  },
  onAdd: function (map) {
    GeoJSON.prototype.onAdd.call(this, map);
    this.setStyle();
  },

  setStyle: function () {
    this._validateAttributes();

    let attributes = this.options.attributes;
    let style = this.options.style;

    let layers = this._layers;
    for (let key in layers) {
      let layer = layers[key];
      let id = layer.feature.properties[attributes.id];
      let value = null;
      if (this.options.data == null)
        value = layer.feature.properties[attributes.value];
      else value = this.options.data[id][attributes.value];
      let fillColor = style.fillColorNull;
      if (!(value == null)) fillColor = this._getColor(value);
      let styleCopy = Object.assign({}, style);
      styleCopy.fillColor = fillColor;

      var index = this._selectedIds.indexOf(id);
      if (index > -1) {
        index = index % style.colorSelected.length;
        styleCopy.color = style.colorSelected[index];
        styleCopy.fillColor = style.colorSelected[index];
        styleCopy.fillOpacity = 0.1;
        styleCopy.weight = style.weight * 2;
        styleCopy.opacity = 1.0;
        layer.bringToFront();
      }
      layer.setStyle(styleCopy);
    }
  },
  updateStyle: function (style) {
    this.options.style = style;
    this.setStyle();
  },

  selectFeature: function (id, clear = false) {
    if (clear) this.clearSelection();
    if (this._selectedIds.indexOf(id) == -1) {
      this._selectedIds.push(id);
    }

    this.setStyle();
  },

  unselectFeature: function (id) {
    const index = this._selectedIds.indexOf(id);
    if (index == -1) this._selectedIds.splice(index, 1);

    this.setStyle();
  },

  clearSelection: function () {
    delete this._selectedIds;
    this._selectedIds = [];

    this.setStyle();
  },

  getSelected: function () {
    let selection = {};
    let style = this.options.style;

    for (let i in this._selectedIds) {
      let item = this._selectedIds[i];
      let index = this._selectedIds.indexOf(item);
      index = index % style.colorSelected.length;
      selection[item] = style.colorSelected[index];
    }

    return selection;
  },
});

export const customPolygon = (geojson, options) => {
  return new CustomPoly(geojson, options);
};
